import React from "react";
import { content } from "../assets/data";
// import AboutContentImg from "../assets/images/AboutContentImg.jpg";
import ImageSlider from "./ImageSlider";
import BtnStyle from "./common/BtnStyle";
// import BtnStyle from "./common/BtnStyle";

const AboutContent = () => {
  return (
    <div className="w-full lg:px-28 lg:py-20 md:p-8 p-4 lg:h-full h-auto">
      <div className="w-full flex flex-col items-start">
        <h1 className="text-center lg:text-5xl md:text-3xl text-2xl font-bold tracking-tight text-Cdark font-['Kalnia',_serif] sm:text-5xl md:mb-5 mb-2">
          About us
        </h1>
        <span className="w-full h-1 bg-violet-700 rounded-tr-lg rounded-tl-lg md:mb-5"></span>
      </div>
      <div className="flex h-full md:flex-row justify-between md:gap-10 gap-4 my-4 flex-col">
        <div className="left w-full border-0 h-full border-red-500 flex justify-start items-start flex-col md:gap-4 gap-2">
            <div className="flex w-full  items-start">
              {/* <span className="w-5 h-full bg-violet-700 block"></span> */}
              <h1 className="lg:text-3xl md:text-2xl text-xl font-bold uppercase text-Cdark">
              Crafting flawless digital solutions to <span className="text-violet-500">boost</span> your experience!
              </h1>
            </div>
          <p className="lg:text-xl text-sm md:text-sm text-Cdark font-desc">
            {content.aboutPageContent}
          </p>
          
        </div>
        <div className="right w-full border-0  md:h-[450px] h-[300px] border-red-500 flex justify-center items-center flex-col ">
          <div className="h-full w-full my-auto">
          <ImageSlider />
          </div>
          <h1 className="text-lg mt-2 font-bold font-['Kalnia',_serif]">Our Top Products</h1>
        </div>
      </div>
      <BtnStyle content={{text:"More About", to:"/about"}} />
    </div>
  );
};

export default AboutContent;
