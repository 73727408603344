import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/RestaurantManagement1.png";
import image2 from "../../assets/images/RestaurantManagement2.png";
import image3 from "../../assets/images/RestaurantManagement3.png";
import image5 from "../../assets/images/RestaurantManagement5.png";
import image6 from "../../assets/images/RestaurantManagement6.png";
import image7 from "../../assets/images/RestaurantManagement7.png";
import image4 from "../../assets/images/ResturantManagement4.png";
import Footer from "../Footer";
const RestaurantManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-Cdark">
            DigIT-Hack Restaurant Management System
          </h1>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Manages all your restaurant operations efficiently so that you can
          focus on growing your brand, like a real boss! We provide a one stop
          solution for all the activities related to restaurant business, right
          from finance part till the customer relation part.
        </p>
        <div className="flex w-full ">
          <div className="left w-full">
            <ul className="list-none">
              <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
                ✓Fine Dine Restaurants
              </li>
              <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
                ✓Fast Food & Cafes
              </li>
              <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
                ✓Food Trucks
              </li>
            </ul>
          </div>
          <div className="right w-full">
            <ul className="list-none">
              <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
                ✓Quick Service Restaurants
              </li>
              <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
                ✓Retail Stores
              </li>
              <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
                ✓Ice Cream Parlor
              </li>
            </ul>
          </div>
        </div>
        <img src={image1} alt="" className="mx-auto w-3/4" />
        <h2 className="text-2xl font-semibold text-start">Our Key Features </h2>

        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            • Minimal clicks leading to all kind of billings
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            • Inventory Management (Live stock updates)
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            • Staff Management
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            • Kitchen Management
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            • Digital Ordering System (Including QRs for Dine In)
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            • Integration with Online Orders
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">Billing System</h2>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Customizable bill format
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Print your restaurant logo, create bill break-ups, edit customer
          details or add a dynamic QR code for payments right at the billing
          counter - everything is possible with DigIT-Hack restaurant POS
          software
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Multi-terminal billing -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          In need of multiple billing terminals for your different areas and
          menus? Don’t worry! DigIT-Hack POS lets you easily create multiple
          billing counters and sync them with one master station so that your
          captains can generate KOTs and settle bills from the right station.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Station-wise KOT printing -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Got different cooking stations? No worries! Assign a unique printer to
          every station and send KOTs to the respective stations. Smoothly sync
          them all with the master POS to keep track of all your orders and
          their running status.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Table and area management -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Big restaurants, big problems. Minimize your problems by making your
          area and table management simple with DigIT-Hack restaurant POS.
          Configure different dine-in areas with their respective menus, service
          tax rates and customizable seating arrangements
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Configure taxes & discounts -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Switch to a restaurant billing software that lets you easily configure
          and levy different taxes, update tax rates and offer discounts
          depending on your service types, regional norms and business needs!
        </p>
        <img src={image2} alt="" className="mx-auto w-3/4" />
        <h2 className="text-xl lg:text-2xl font-semibold">
          Inventory Management –
        </h2>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Central kitchen module -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          An inventory management software that lets you create a central
          kitchen for all your single & multiple outlets. Request or supply
          inventory, manage suppliers, and return the damaged stock back to its
          source without hassle!
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Raw material management -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Plan your inventory restocking and set ‘low stock’ alerts on
          DigIT-Hack’s inventory management system. Easily manage the
          availability of menu items both for dine-in & online menu.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Multi-stage recipe management -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          A restaurant POS system designed to automatically deduct your stock
          when used. Upload your item-wise recipe in the software & let the
          smart POS look after your consumption rate at every stage without you
          having to worry about stock-outs!
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Purchase order management -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Raise & accept purchase order tickets directly from your suppliers or
          your central kitchen right from the POS dashboard. Keep track of the
          frequently ordered items from each supplier and particular outlet or
          zone.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Inventory management for Retail Briefing
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Sales – Purchase – Stock – Warehouse – Reports – Suppliers – Reconcile
        </p>
        <img src={image3} alt="" className="mx-auto w-3/5" />
        <h2 className="text-xl lg:text-2xl font-semibold">
          Recipe Inventory Management for QSR, Cafes & Restaurants -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Create Recipe <br />
          Multiple Measuring Units <br />
          Raw Materials <br />
          Items Costing <br />
          Order wise Consumption <br />
          Manual Consumption <br />
          Waste Items Management <br />
          Reports & Analytics
          <br />
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Order Management -
        </h2>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Online ordering integrations -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Don’t shuffle between different screens to accept orders. Do all of it
          and more from a single screen easily with DigIT-Hack restaurant POS
          software! Seamlessly integrate your system with Swiggy, Zomato,
          Dineout, etc., to increase your restaurant sales.
          <br />
          ✓Delivery apps orders and its management directly on DigIT-Hack <br />
          ✓Enable & disable menu items on delivery app from DigIT-Hack <br />
          ✓Options like menu sync, Locations sync etc.
        </p>
        <img src={image4} alt="" className="mx-auto w-3/4" />
        <h2 className="text-xl lg:text-2xl font-semibold">
        Regulate menu availability -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Add store timing, packaging charges, and discounts. Easily manage the online menu for your single or chain outlets from one single screen. Cross-check the stock availability with your DigIT-HACK POS system and toggle any online menu item On or Off as per your inventory!
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Menu updates & changes -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Easily add new menu items, offer different kinds of packaging and delicious add-ons, launch special offers or update menu prices across all your aggregators without hassles with your DigIT-Hack restaurant billing software.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Online order reconciliation -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        DigIT-Hack POS tracks and compiles platform-wise reconciled reports about your online orders, their commission rate, surplus charges, profit margins, taxes, etc., so that you don’t lose your hard-earned profits to wrongful payout reports.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Real-time insightful reports of your restaurant (Analytics) –
        </h2>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Single dashboard monitoring -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Don’t crush yourself and your restaurant business under stacks of paper files and complicated excel sheets. Switch to DigIT-Hack restaurant billing software and get all your data and more at one single screen!
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Easy tax reporting -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Confused about how to simplify the taxation process for your business? Let DigIT-Hack POS software prepare downloadable reports for simpler and exact taxation! Single or chain outlet, the POS keeps track of every income and allows you to file taxes in a jiffy. 
        </p>
        <img src={image6} alt="" className="mx-auto w-3/4 mb-4" />
        <img src={image7} alt="" className="mx-auto w-3/4" />
        <h2 className="text-xl lg:text-2xl font-semibold">
        User rights management -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Regulate staff access and controls what they see or do in your absence. Get detailed reports on bill modifications, discounted/cancelled orders, cash drawer operations, and much more to maintain centralized control over your outlet’s operations.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Head office module -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        With DigIT-Hack, data from all your outlets can be monitored on a single dashboard. City-wise and Zone-wise groupings allow you to aggregate data the way you want. Head-office module also allows you to centrally manage all your menus and raw material masters seamlessly.
        </p>
        
        <img src={image5} alt="" className="mx-auto w-3/5" />
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        We can customize as per the requirement. We will make sure that we can solve all your problems, and will also make your customer relation smooth and can make your business accessible to you on your fingerprints.
        </p>
        
        <h3 className="text-xl lg:text-2xl font-semibold font-desc">
        Contact us to schedule a demo or discuss how our software can optimize your restaurant management!
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default RestaurantManagement;
