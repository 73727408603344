import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {TestimonialData} from "../assets/data";

const Slide = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="w-full lg:px-28 lg:py-20 md:p-8 overflow-hidden">
      <div className="w-full flex flex-col items-start mb-10 px-4">
        <h1 className="text-center lg:text-5xl md:text-3xl text-2xl font-bold tracking-tight text-Cdark font-['Kalnia',_serif] sm:text-5xl md:mb-5 mb-2">
          Testimonial
        </h1>
        <span className="w-full h-1 bg-violet-700 rounded-tr-lg rounded-tl-lg md:mb-10"></span>
      </div>
      <Slider {...settings}>
        {TestimonialData.map((d) => (
          <div
            key={d.name}
            className="bg-gray-100 h-[480px] text-black rounded-xl mx-4 shadow-lg"
            style={{ width: "300px !important" }}
          >
            <div className="h-56 bg-violet-600 flex justify-center items-center rounded-t-xl">
              <img src={d.image} alt="" className="h-full md:w-full w-full rounded-md" />
            </div>

            <div className="flex flex-col items-center justify-center gap-4 p-4">
              <p className="text-center font-bold text-black">{d.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default Slide;
// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// const Slide = () =>  {

//     const settings = {
//       dots: true,
//       infinite: true,
//       slidesToShow: 3,
//       slidesToScroll: 1,
//       autoplay: true,
//       autoplaySpeed: 2000
//     };
//     return (
//       <div>
//         <Slider {...settings}>
//         {data.map((d) => (
//           <div
//             key={d.name}
//             className="bg-white h-[450px] text-black rounded-xl mx-4"
//           >
//             <div className="h-56 bg-indigo-500 flex justify-center items-center rounded-t-xl">
//               <img src={d.img} alt="" className="h-44 w-44 rounded-full" />
//             </div>

//             <div className="flex flex-col items-center justify-center gap-4 p-4">
//               <p className="text-xl font-semibold">{d.name}</p>
//               <p className="text-center">{d.review}</p>
//             </div>
//           </div>
//         ))}
//         </Slider>

//       </div>
//     );
//   }
// export default Slide;
