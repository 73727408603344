import React from "react";
import Category from "./components/Category";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/About";
import AdvocateOffice from "./components/Products/AdvocateOffice";
import CAOffice from "./components/Products/CAOffice";
import DigitalMarketing from "./components/Products/DigitalMarketing";
import ERPSoftware from "./components/Products/ERPSoftware";
import BillingSoftware from "./components/Products/BillingSoftware";
import GymManagement from "./components/Products/GymManagement";
import HotelManagement from "./components/Products/HotelManagement";
import HRandPayroll from "./components/Products/HRandPayroll";
import JewelleryManagement from "./components/Products/JewelleryManagement";
import VisitingCard from "./components/Products/VisitingCard";
import WebsiteandApplication from "./components/Products/WebsiteandApplication";
import VisitorManagement from "./components/Products/VisitorManagement";
import RestaurantManagement from "./components/Products/RestaurantManagement";
import HospitalManagement from "./components/Products/HospitalManagement";
import TransportManagement from "./components/Products/TransportManagement";
import SchoolManagement from "./components/Products/SchoolManagement";
import TailoringErp from "./components/Products/TailoringErp";
import SalonManagement from "./components/Products/SalonManagement";

function App() {

  return (
<BrowserRouter>
<Routes>
  <Route path="/" element={<Home/>}/>
  <Route path="/about" element={<About/>}/>
  <Route path="/category" element={<Category/>}/>
  <Route path="/category/AdvocateOffice" element={<AdvocateOffice/>}/>
  <Route path="/category/CAOffice" element={<CAOffice/>}/>
  <Route path="/category/DigitalMarketing" element={<DigitalMarketing/>}/>
  <Route path="/category/ERPSoftware" element={<ERPSoftware/>}/>
  <Route path="/category/BillingSoftware" element={<BillingSoftware/>}/>
  <Route path="/category/GymManagement" element={<GymManagement/>}/>
  <Route path="/category/HotelManagement" element={<HotelManagement/>}/>
  <Route path="/category/HRandPayroll" element={<HRandPayroll/>}/>
  <Route path="/category/JewelleryManagement" element={<JewelleryManagement/>}/>
  <Route path="/category/VisitingCard" element={<VisitingCard/>}/>
  <Route path="/category/WebsiteandApplication" element={<WebsiteandApplication/>}/>
  <Route path="/category/VisitorManagement" element={<VisitorManagement/>}/>
  <Route path="/category/RestaurantManagement" element={<RestaurantManagement/>}/>
  <Route path="/category/HospitalManagement" element={<HospitalManagement/>}/>
  <Route path="/category/TransportManagement" element={<TransportManagement/>}/>
  <Route path="/category/SchoolManagement" element={<SchoolManagement/>}/>
  <Route path="/category/TailoringErp" element={<TailoringErp/>}/>
  <Route path="/category/SalonManagement" element={<SalonManagement/>}/>
  

</Routes>
</BrowserRouter>
  );
}

export default App;
