import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/BillingSoftware-1.png";
import image2 from "../../assets/images/BillingSoftware2.jpg";
import Footer from "../Footer";
import image3 from "../../assets/images/BillingSoftware-3.jpg";

const BillingSoftware = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-Cdark">
            DigIT-Hack Billing Software
          </h1>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          At DigIT-Hack, we are dedicated to revolutionizing the way businesses
          manage their billing processes. With a commitment to innovation and
          user-centric solutions, we offer a comprehensive billing software
          designed to simplify invoicing and streamline financial operations.
        </p>
        <img src={image1} alt="" className="mx-auto w-1/3" />
        <h2 className="text-xl lg:text-3xl font-semibold">Our Features</h2>
        <h2 className="text-xl lg:text-2xl font-semibold">mPOS:</h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Our billing Pos system supports mobile devices such as smartphones
            and tablets or Laptops.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            This functionality empowers businesses to effortlessly process
            payments and efficiently manage sales transactions on the move,
            eliminating the need for dedicated billing counters.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Credit Management:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Billing software keeping track of how much money your customers owe
            you and making sure they pay it back on time.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            It also involves deciding how much credit to give to each customer
            and checking if they can be trusted to pay you back.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Create Staff/ Define User Control:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            The management and regulation of user access and permissions within
            the application.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            It involves defining and assigning specific roles and privileges to
            staff members or users based on their responsibilities and
            requirements.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Online Bill receipts on customer phone:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            An online bill receipt in a customer billing app is like a digital
            receipt you get after buying something using the app.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            It tells you what you bought, how much you paid, and how you paid
            for it. You can easily access it on your device.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          GST Reports of Sale & Purchase:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            A GST report of sale and purchase is a record that shows all the
            things a business sold and bought in a certain time, along with the
            taxes involved.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            It helps the business follow the rules and calculate the right
            amount of taxes they owe.
          </li>
        </ul>
        <img src={image2} alt="" className="mx-auto w-1/2" />
        <h2 className="text-xl lg:text-2xl font-semibold">Barcode Scan:</h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            The barcode scan feature in a billing app lets you use your phone's
            camera to quickly scan the barcodes on products.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            This helps you easily and accurately add those products to your bill
            or keep track of your inventory. It's like a speedy and error-free
            way to identify and record items.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Multiplatform Billing App:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Having a multiplatform billing app ensures that businesses and users
            can access and use the application regardless of their preferred
            device or operating system like Android, Windows.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Cloud Data Storage:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Cloud data storage for a billing app means keeping all the important
            billing information, like invoices and customer details, on the
            internet instead of on your computer.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            It's like having a virtual safe place for your data that you can
            access from anywhere with an internet connection.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Generate Bill In One Click:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Clicking once to create a bill simplifies and accelerates the
            billing process. It's like a shortcut for billing." It saves time
            and enhances efficiency, making it a valuable feature for businesses
            and individuals who need to create invoices quickly and
            effortlessly.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Track Your Inventory:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Built-in funcionality allows you to track inventory/stock.
            Auto-update inventory whenever you create an Invoice.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Notify you about low stock, stock availability and more.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Customer Loyalty Display:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Let your point of sale tell you through customer profiles and sales
            history logs. Then, use that customer insight to create an impactful
            loyalty program.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">Customer Wallet:</h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            This wallet is associated with a specific customer and holds any
            credit balances or extra payments they may have made.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            It simplifies financial transactions for both customers and
            businesses.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">Invoice Printing:</h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          DigIT-Hack billing app supports multiple type of PoS receipt printers
            and comes with super fast printing capability.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          *Advance marketing tools:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Create automated campaigns and target customers via sms and push
            notification.
          </li>
        </ul>
        <div className="flex flex-wrap items-center">
          <img src={image3} alt="" />
          <div className="">
            <h2 className="text-xl lg:text-2xl font-semibold">
              Ready to Streamline Your Operations?
            </h2>
            <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
              Transform your workplace efficiency and customer satisfaction with
              our management software.
            </p>
          </div>
        </div>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Contact us to schedule a demo or discuss how our software can optimize
          your billing process!
        </h2>
      </div>
      <Footer />
    </div>
  );
};

export default BillingSoftware;
