import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/HotelManagement1.png";
import image2 from "../../assets/images/HotelManagement2.png";
import Footer from "../Footer";

const HotelManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-Cdark">
            Hotel Management Software
          </h1>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Elevate Your Hotel Operations with Our Comprehensive Management
          Software! Welcome to DigIT-Hack, where we redefine hotel management
          with our feature-rich and intuitive software tailored for the
          hospitality industry. Our solution is designed to streamline hotel
          operations, enhance guest experiences, and drive efficiency in every
          aspect of your establishment.
        </p>
        <img src={image1} alt="" className="mx-auto w-3/4" />
        <h2 className="text-2xl font-semibold text-start">Key Features -</h2>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Reservation and Booking Management –
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Effortless Bookings: Manage room reservations seamlessly, including
          online bookings, walk-ins, and reservations through third-party
          platforms. Availability and Pricing: Monitor room availability in
          real-time and set dynamic pricing strategies for maximum occupancy.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Front Desk Operations -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Check-In/Check-Out: Expedite guest check-in and check-out processes,
          ensuring a smooth experience for your guests. Guest Profiles: Maintain
          comprehensive guest profiles, including preferences and past stays, to
          personalize their experience.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Housekeeping and Maintenance -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Task Assignment: Assign housekeeping tasks efficiently and track room
          cleaning status in real-time. Maintenance Requests: Manage maintenance
          requests promptly, ensuring a well-maintained and comfortable
          environment for guests.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Kitchen Management -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          In-Room digital food ordering system. Inventory Management.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Billing and Invoicing -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Automated Billing: Generate accurate invoices, track payments, and
          manage billing effortlessly. Integration with POS: Seamlessly
          integrate billing with the Point of Sale (POS) system for restaurant
          and other services.
        </p>

        <h2 className="text-xl lg:text-2xl font-semibold">
          Analytics and Reporting -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Performance Insights: Access comprehensive analytics and reports to
          make data-driven decisions for optimizing operations and maximizing
          revenue. Forecasting Tools: Utilize forecasting tools to anticipate
          demand and adjust strategies for improved performance.
        </p>
        <img src={image2} alt="" className="mx-auto w-3/5" />
        <h2 className="text-xl lg:text-2xl font-semibold">Why Choose Us?</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Tailored for Hospitality: Built specifically for the unique needs of the hospitality industry, ensuring it addresses challenges and enhances efficiency.  
Scalability and Integration:  Scalable to fit properties of various sizes and capable of integrating with other hotel systems for a seamless workflow. 
Support and Training: Benefit from our dedicated support team, providing training and assistance to ensure effective implementation and continued support. 

        </p>
        <h3 className="text-xl lg:text-2xl font-desc mb-2">
        Ready to Streamline Your Hotel Operations? Transform your hotel's efficiency and guest satisfaction with our management software. 
        <br />
        <span className="font-bold">Contact us to schedule a demo or discuss how our software can optimize your hotel management!</span>
        

        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default HotelManagement;
