import React, { useEffect } from "react";
import Navbar from "../NavBar";
// import image1 from "../../assets/images/VisitorManagement1.png";
import image2 from "../../assets/images/VisitorManagement2.png";
// import image3 from "../../assets/images/VisitorManagement3.png";
import Footer from "../Footer";
const VisitorManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-Cdark">
            DigIT-Hack Visitor Management System
          </h1>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          DigIT-Hack, a security solution providing IT company that specializes
          in Visitor Management System. DigIT-Hack presents a unique customized
          visitor management software, which is not just user-friendly, but also
          paperless and eco-friendly, having authenticated data points which can
          be accessed by a click of button at any point of time 24X7 to retrieve
          details of the visitors.
        </p>
        {/* <img src={image1} alt="" className="mx-auto w-3/4" /> */}

        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          It is a revolutionary cloud based system to record details. It
          replaces manual visitor sign-in process and brings digital approach.
          As a fully digitized system, it streamlines the visitor check-in
          process by verifying the number, capturing images, sending
          notifications etc. The system is designed to protect you from unwanted
          visitors, ensuring that only authorized visitors can enter the
          premises.
        </p>

        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          We have a team of highly trained professionals, who are committed,
          capable and ambitious with the highest work ethics. We have always
          believed in addressing a problem with multiple frames of references
          and then evolve a solution keeping the area of distress in mind.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">Our Vision -</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          DigIT-Hack has its main inclination towards security. Our main
          objective is to ensure that every premise should get DigIT-Hack
          Visitor Management System where they have power to control everything
          by their hands.
          <br />
          It stores information on our cloud based server
          <br />
          Issues entry passes for visitors who is visiting your premises
          <br />
          Has the authorization to grant the permission
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">Our Mission -</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc mb-2">
          Our mission is to bid adieu to the ancient manual sign-in & build a
          fully featured and secured digitized premises.
          <br />
          This will be an asset to your premises and you no more need to worry
          about storing and maintaining authentic record for a lifetime.
        </p>

        <img src={image2} alt="" className="mx-auto w-3/4" />

        <h2 className="text-xl lg:text-2xl font-semibold mt-2">Why Choose Us?</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          DigIT-Hack is a next-gen technology that is guided by a team of
          innovative and qualified professionals who believe in making a change
          by looking at things differently.Now, keeping the safety of premises
          in mind, DigIT-Hack has come up with this new technology altogether,
          which takes over the manual register technique by a digital modern
          software.
        </p>
        <h2 className="text-xl lg:text-2xl ">
          We can customize as per the requirement. We will make sure that we can
          solve all your problems, and will also make your visitor’s tracking
          and security level accessible to you on your fingerprints.
        </h2>

        <h3 className="text-xl lg:text-2xl font-semibold font-desc">
          Contact us to schedule a demo or discuss how our software can optimize
          your visitor’s management!
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default VisitorManagement;
