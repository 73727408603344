import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/Websitepage1.png";
import image2 from "../../assets/images/Websitepage2.png";
import Footer from "../Footer";
const WebsiteandApplication = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-Cdark">
            Website And Application Development
          </h1>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          At DigIT-Hack, we craft bespoke digital solutions to elevate your
          online presence and optimize user experiences. Our expert team
          specializes in creating dynamic websites and intuitive applications
          tailored to meet your specific business needs.
        </p>
        <img src={image1} alt="" className="mx-auto w-3/5" />
        <h2 className="text-xl lg:text-2xl font-semibold">
          Website Development
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Our website development services focus on creating visually stunning,
          user-friendly, and responsive websites that leave a lasting
          impression. Whether it's a corporate website, e-commerce platform, or
          a specialized site for your industry, we ensure a seamless and
          engaging user experience.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Key Offerings:- 

        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Custom Design: Unique and visually appealing designs that align with your brand identity.
Responsive Development: Websites optimized for all devices, ensuring an exceptional user experience across desktops, tablets, and mobiles.
E-commerce Solutions: Robust online stores equipped with secure payment gateways and intuitive shopping experiences.
Content Management Systems (CMS): Easy-to-manage platforms that empower you to update content effortlessly. 

        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Application Development -

        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        From concept to launch, our application development services cover a wide spectrum, including mobile apps, enterprise solutions, and innovative software. We focus on delivering scalable, high-performance applications that meet your business objectives.

        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Key Offerings:- 

        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Mobile Apps: Android and IOS applications tailored to your specifications and user preferences.
Enterprise Solutions: Custom software designed to streamline internal processes and enhance productivity.-
<br/>
User-Centric Design: Intuitive interfaces and user experiences to drive engagement and retention.
API Integration: Seamless integration with third-party services to expand functionality and accessibility.

        </p>
        
        <h2 className="text-xl lg:text-2xl font-semibold">Why Choose Us?</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Tailored Solutions: Customized strategies and designs crafted to align with your business goals and objectives.

        </p>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Expert Team: A dedicated team of experienced professionals proficient in the latest technologies and trends.

        </p>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Scalability and Support: Solutions that scale as your business grows, backed by ongoing support and maintenance.


        </p>
        <img src={image2} alt="" className="mx-auto w-3/6" />
        <h2 className="text-xl lg:text-2xl">
        <span className="italic">Ready to Elevate Your Digital Presence?</span> Let us be your partner in digital transformation. Whether it's a stunning website or a powerful application, we have the expertise to bring your vision to life. 

        </h2>

        <h3 className="text-xl lg:text-2xl font-semibold font-desc">
        Contact us to discuss how we can create a digital solution that propels your business forward!

        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default WebsiteandApplication;
