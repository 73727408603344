// ContactUsPage.js
import React, { useState } from "react";
import Modal from "./common/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  // const [name, setName] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [isModal, setModal] = useState(false);
  const handleOpenModal = () => {
    setModal(true);
  };
  const handleCloseModal = (status) => {
    if(status === "success"){
      toast.success("Email Sent", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }else if(status === "error"){
      toast.error("Something went wrong try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setModal(false);
  };
  // const handleNameChange = (e) => {
  //   setName(e.target.value);
  // };

  // const handlePhoneNumberChange = (e) => {
  //   setPhoneNumber(e.target.value);
  // };



  return (
    <div className="container w-full lg:px-28 lg:py-20 md:p-8 p-4">
      {/* <div className="w-full flex flex-col items-start">
        <h1 className="text-center lg:text-5xl md:text-3xl text-2xl font-bold tracking-tight text-Cdark font-['Kalnia',_serif] sm:text-5xl md:mb-5 mb-2">
          Contact us
        </h1>
        <span className="w-full h-1 bg-violet-700 rounded-tr-lg rounded-tl-lg md:mb-5"></span>
      </div> */}

      <div className="flex lg:flex-row flex-col gap-2 justify-center items-center">
        <h1 className="text-2xl md:text-3xl lg:text-5xl  font-['Kalnia',_serif]">To explore more, contact us for a demo of any product you like!</h1>
      <button
        className={`text-white lg:text-xl font-semibold border-2 bg-violet-700 animate-bounce font-['Kalnia',_serif] lg:p-x-2 p-3 rounded-xl w-64`}
        onClick={handleOpenModal}
      >
        Schedule a Demo
      </button>
      </div>
      <ToastContainer  />
     
      {isModal && <Modal handleCloseModal={handleCloseModal} />}
    </div>
  );
};

export default ContactUs;
