import React from "react";
import Navbar from "../NavBar";
import CominSoon from "../common/CominSoon";

const SalonManagement = () => {
  return (
    <div className="w-screen h-screen flex flex-col">
      <Navbar color={"black"} />
      <CominSoon />
    </div>
  );
};

export default SalonManagement;
