import React from "react";
import Logo from "../assets/images/finalLogo.png";
import { Link } from "react-router-dom";
import footerImage from "../assets/images/footer.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
} from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";
const Footer = () => {
  return (
    <footer
      className="bg-violet-500 w-full lg:px-28 lg:py-10 md:p-8 p-4"
      id="footer"
      style={{ backgroundImage: `url('${footerImage}')` }}
    >
      <div className="flex lg:flex-nowrap flex-wrap gap-3">
        <div className="left logo gap-4 flex flex-col justify-center  w-full">
          <img src={Logo} alt="" className="lg:w-40 w-32" />
          <div className="socilaMedia flex gap-3">
            <Link
              to="https://www.linkedin.com/company/digithack7/"
              className="md:w-9 w-8 h-8 md:text-xl md:h-9 rounded-full  flex items-center justify-center bg-white hover:bg-violet-700 hover:text-white transition-all duration-300"
            >
              <FaLinkedin />
            </Link>
            <Link
              to="https://x.com/DigitiHack?t=w4TVmIitijqQNPrCgNuT2A&s=08"
              className="md:w-9 w-8 h-8 md:text-xl md:h-9 rounded-full  flex items-center justify-center bg-white hover:bg-violet-700 hover:text-white transition-all duration-300"
            >
              <FaXTwitter />
            </Link>
            <Link
              to="https://instagram.com/digithack_?igshid=OGQ5ZDc2ODk2ZA=="
              className="md:w-9 w-8 h-8 md:text-xl md:h-9 rounded-full  flex items-center justify-center bg-white hover:bg-violet-700 hover:text-white transition-all duration-300"
            >
              <FaInstagram />
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=61554771653104&mibextid=ZbWKwL"
              className="md:w-9 w-8 h-8 md:text-xl md:h-9 rounded-full flex items-center justify-center bg-white hover:bg-violet-700 hover:text-white transition-all duration-300"
            >
              <FaFacebookF />
            </Link>
          </div>
        </div>
        <div className="middle quick-links w-full">
          <h1 className="text-black lg:text-3xl text-xl font-bold lg:mb-4 mb-2">Quick Links</h1>
          <ul className="text-white">
            <li className="mb-2 lg:text-lg ">
              <Link to="/">Home</Link>
            </li>
            <li className="mb-2 lg:text-lg">
              <Link to="/about">About us</Link>
            </li>
            <li className="mb-2 lg:text-lg">
              <Link to="/category">Services</Link>
            </li>
          </ul>
        </div>
        <div className="right getInTouch w-full flex flex-col gap-4">
          <h1 className="text-black lg:text-3xl text-xl font-bold ">Get in touch</h1>
          <div className="flex gap-2 items-center">
            <IoLocationSharp className="text-white text-4xl" />
            <div className="flex flex-col">
              <h1 className="lg:text-xl text-lg text-white">Address</h1>
              <p className="text-white lg:text-lg">
                Hbr layout, hennur cross, bangalore, Karnataka, 560043
              </p>
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <FaPhoneAlt className="text-white text-2xl" />
            <div className="flex gap-2 items-center">
              <h1 className="lg:text-xl text-lg text-white">Phone</h1>
              <div className="">
                <p className="text-white lg:text-lg">+91 7657024042</p>
                <p className="text-white lg:text-lg">+91 7653011501</p>
              </div>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <MdMail className="text-white text-2xl" />
            <div className="flex flex-col">
              <h1 className="lg:text-xl text-lg text-white">Email</h1>
              <p className="text-white lg:text-lg">info@digithack.in</p>
            </div>
          </div>
        </div>
      </div>

      <hr className="mt-4" />
      <div className="text-white">
        <p className="lg:text-lg">
          In collaboration with
          <a href="http://www.foxtroit.com/" className="font-bold text-black">
            {" "}
            FOXTROIT
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
