import React, { useEffect, useRef } from "react";
import video from "../assets/videos/background-video.mp4";
import BtnStyle from "./common/BtnStyle";
import Navbar from "./NavBar";
import AboutContent from "./AboutContent";
import Card, { AnimatedCard } from "./Card";
import Industries from "../assets/data";
// import { content } from "../assets/data";
import Footer from "./Footer";
import Slide from "./Slide";
import Catalog from "./Catalog";
import ContactUs from "./ContactUs";

const Home = () => {
  const contactRef = useRef(null);
  const handleContactClick = () => {
    contactRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === '#contact') {
        window.scrollTo(500, 0);
      }
    };
  
    window.addEventListener('hashchange', handleHashChange);
  
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
    // console.log(window.location.hash);
  }, []);
  return (
    <>
      <div className="min-h-screen flex items-center justify-center relative font-desc">
        <div className="absolute top-0 z-[5] w-full">
          <Navbar color={"white"} contact={handleContactClick} />
        </div>
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          style={{width:"100vw"}}
          autoPlay
          loop
          muted
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 w-full h-screen bg-black opacity-70 z-[2]"></div>
        <div className="z-[3] text-white text-center flex justify-center items-center flex-col bg-violet-100/20 h-screen w-full py-4">
          <div className="flex flex-col justify-center items-center  mt-52 ">
            <h1 className="lg:text-6xl md:text-5xl text-3xl font-bold mb-4 capitalize font-['Kalnia',_serif]">
              You Dream, We Customize.
            </h1>
            <div className="my-4">
              <div className="" onClick={()=>{window.scrollBy(0,800)}}>
              <BtnStyle content={{ text: "Explore" }} />
              </div>
            </div>
            <p className="lg:text-xl text-sm">
              Tip to toe under your fingertips!
            </p>
          </div>
        </div>
      </div>
      <AboutContent />
      <section>
        <div className="flex flex-col justify-start bg-[#f1f2f7] bg-no-repeat bg-cover gap-4 lg:px-28 lg:py-20 p-8 overflow-hidden">
          <div className="w-full flex flex-col items-start">
            <h1 className="text-center lg:text-5xl md:text-3xl text-2xl font-bold tracking-tight text-Cdark font-['Kalnia',_serif] sm:text-5xl  md:mb-5 mb-2">
              Our Services
            </h1>
            <span className="w-full h-1 bg-violet-700 rounded-tr-lg rounded-tl-lg  md:mb-5"></span>
          </div>
          <p className="lg:text-xl text-sm  md:text-sm text-Cdark font-desc">
            Our versatile software suite is tailored for various industries,
            simplifying operations with intuitive tools and functionalities.
            From Retail Business to Healthcare management, we offer a broad
            range of solutions designed to streamline processes and enhance
            productivity. We can provide drifferent services as per different
            indurstries requirement.
          </p>
          <div className="flex flex-wrap justify-center w-full gap-x-4 ">
            {Industries.slice(0, 5).map((item) => {
              return <Card key={item.id} item={item} btn={true} />;
            })}
            <AnimatedCard />
          </div>
        </div>
      </section>
      <section className="mb-10 lg:mb-0">
        <Catalog />
      </section>
      <section className="Testimonial">
        <Slide />
      </section>
      <ContactUs ref={contactRef} />
      <Footer />
    </>
  );
};

export default Home;
